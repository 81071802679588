import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { format } from 'date-fns'
import {FiCalendar, FiUser} from 'react-icons/fi'
import {BiCategory} from 'react-icons/bi'
import React from 'react'
import PageSpace from '../components/PageSpace'
import SEO from '../components/SEOFile'
import ParagraphText from '../components/typography/ParagraphText'
import { RegularTitle } from '../components/typography/Title'
import {SingleBlogStyles} from '../styles/blog/SingleBlogStyles'
import MyPortableText from '../components/typography/PortableText'
import AdSense from 'react-adsense';

export const postQuery = graphql`
    query SingleBlogQuery($id: String!){
        sanityBlog(id: {eq: $id}){
            title
            publishedAt
            _rawBody
            coverImage{
                alt
                asset {
                    gatsbyImageData
                }
            }
            categories{
                title
                slug {
                    current
                }
            }
            author {
                name
                slug {
                    current
                }
            }
        }
    }
`

function SingleBlog({ data }) {
    const blog = data.sanityBlog;
    return (
        <SingleBlogStyles>
            <SEO title={blog.title} />
            <PageSpace top={80} bottom={100}>
                <div className='container'>
                    <div className="blog-header">
                        <GatsbyImage 
                            image={blog.coverImage.asset.gatsbyImageData}
                            alt={blog.coverImage.alt}
                            className="blog-cover-image"
                        />
                        <RegularTitle className="title">{blog.title}</RegularTitle>
                        <ParagraphText className="publishedAt"><FiCalendar/>{format(new Date(blog.publishedAt), 'p, MMMM-dd-yyyy')}</ParagraphText>
                        <ParagraphText className="categoriesText">
                            <BiCategory/>
                            <span>
                                {blog.categories.map((item, index)=> {
                                    return (
                                        <span key={item.slug.current}>
                                            <Link to={`/categories/${item.slug.current}`}>#{item.title}</Link>
                                            { index < blog.categories.length - 1 ? "  " : ""}
                                        </span>
                                    )
                                })}
                            </span>
                        </ParagraphText>
                        <ParagraphText className='author'>
                            <FiUser/>
                            <Link to={`/authors/${blog.author.slug.current}`}>{blog.author.name}</Link>
                        </ParagraphText>
                        <hr className='hr' />
                        {/* <AdSense.Google
                        client='ca-pub-7363395996736530'
                        slot='6934913824'
                        style={{ display: 'block' }}
                        className="adsbygoogle"
                        format='auto'
                        responsive='true'
                        layoutKey='-gw-1+2a-9x+5c'
                        /> */}
                        <div className='body'>
                            <MyPortableText value={blog._rawBody}/>
                        </div>
                    </div>
                </div>
                {/* <AdSense.Google
                client='ca-pub-7363395996736530'
                slot='6934913824'
                style={{ display: 'block' }}
                className="adsbygoogle"
                format='auto'
                responsive='true'
                layoutKey='-gw-1+2a-9x+5c'
                /> */}
            </PageSpace>
        </SingleBlogStyles>
    )
}

export default SingleBlog

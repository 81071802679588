import styled from 'styled-components';

export const SingleBlogStyles = styled.div`
  max-width: 700px;
  margin: 0 auto;
  .blog-cover-image {
    height: 300px;
    margin-bottom: 2rem;
  }
  .title {
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
  .publishedAt {
    margin-bottom: 0.5rem;
  }
  .body a{
    text-decoration: underline;
    font-size: 16;
  }
  a{
    color: cyan !important;
    font-size: 15px;
    /* background-color: purple; */
  }
  .body h4{
    font-size: 16px;
    background-color: black;
    color: white;
    border-radius: 10px;
    padding: 4px;
    width: 90%;
    text-align: center;
  }
  .categoriesText,
  .author,
  .publishedAt {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    svg {
      height: 20px;
      width: 20px;
    }
    a {
      color: var(--gray);
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .hr {
    margin: 2rem 0;
    color: var(--gray);
  }
  p{
    color: #b2afc9 !important;
    font-size: 18px !important;
  }
  li{
    list-style: initial;
  }
  .body {
    color: #b2afc9 !important;
    font-size: 18px !important;
    margin-top: 2rem;
    h1,
    h2,
    h3,
    h4 {
      margin: 1rem 0;
    }
    .bodyImage {
      margin: 2rem 0;
    }
    .bodyCode {
      margin: 2rem 0;
      font-size: 2rem;
    }
  }
`;
